import React, { useState } from "react";
import { GatewayAsset } from "../../../openapi/model/gatewayAsset";
import DisplayIfUserType from "../../../components/utils/DisplayIfUserType/DisplayIfUserType";
import CreateActionDropdown from "../../assetActions/CreateActionDropdown";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import axios from "axios";
import Config from "../../../Config";
import { objectKeysToSnakeCase } from "../../../actions/helpers/apiService";
import { saveAs } from "file-saver";
import { DropdownProps } from "reactstrap/es/Dropdown";

interface Props extends Omit<DropdownProps, "toggle" | "isOpen"> {
  gateway: GatewayAsset;
  refresh?: () => any;
}

export const getConfigurationFileForAsset = (
  assetId: string,
  companyId: string,
  configurationType?: string,
  configurationFormat?: string,
): Promise<void> => {
  return axios
    .get(Config.apiGateway.URL + `/asset/${assetId}/configuration`, {
      params: objectKeysToSnakeCase({
        configurationType,
        configurationFormat,
        companyId,
      }),
      responseType: "arraybuffer",
    })
    .then(({ data, headers }) => {
      const filename = headers["content-disposition"]
        .split(";")[1]
        .split("filename")[1]
        .split("=")[1]
        .trim()
        .replace(/"/g, "");
      saveAs(new Blob([data], { type: headers.contentType }), filename);
    });
};

const GatewayConfigurationDropdown = (props: Props): JSX.Element => {
  const { gateway, ...rest } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = (): void => setDropdownOpen((prevState) => !prevState);

  const downloadDeviceList = (type: string, format?: string): void => {
    getConfigurationFileForAsset(
      gateway.assetId,
      props.gateway.companyId,
      type,
      format,
    );
  };

  const identifiedGatewayModel: boolean = gateway.model !== undefined;

  return (
    <>
      {props.gateway.manufacturer === "elvaco" && (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} {...rest}>
          <DropdownToggle caret color={"primary"} size={rest.size}>
            Configuration
          </DropdownToggle>
          <DropdownMenu persist={true} right={false} container={"body"}>
            <DisplayIfUserType userTypes={"admin"}>
              {gateway.simCardId !== undefined &&
                props.gateway.simCardId !== "" && (
                  <>
                    <CreateActionDropdown
                      asset={gateway}
                      actionType={"FACTORY_RESET_2100"}
                      actionName={"Factory Reset"}
                      refresh={props.refresh}
                    />
                    <CreateActionDropdown
                      asset={gateway}
                      actionType={"GET_CONFIG"}
                      actionName={"Refresh Config"}
                      refresh={props.refresh}
                    />
                    <CreateActionDropdown
                      asset={gateway}
                      actionType={"SYNC_CONFIG"}
                      actionName={"Sync Config"}
                      refresh={props.refresh}
                    />
                    <CreateActionDropdown
                      asset={gateway}
                      actionType={"SYNC_DEVICE_LIST"}
                      actionName={"Sync Devices"}
                      refresh={props.refresh}
                    />
                    <DropdownItem divider />
                    <CreateActionDropdown
                      asset={gateway}
                      actionType={"SYNC_CONFIG_SMS"}
                      actionName={"Sync Config (via SMS)"}
                      refresh={props.refresh}
                    />
                    <CreateActionDropdown
                      asset={gateway}
                      actionType={"SYNC_DEVICE_LIST_SMS"}
                      actionName={"Sync Devices (via SMS)"}
                      refresh={props.refresh}
                    />
                    <DropdownItem divider />
                    <CreateActionDropdown
                      asset={gateway}
                      actionType={"FIRMWARE_UPGRADE"}
                      actionName={"Firmware Upgrade"}
                      refresh={props.refresh}
                    />
                    <CreateActionDropdown
                      asset={gateway}
                      actionType={"SETUP_WIRELESS_RECEIVERS"}
                      actionName={"Setup Wireless Receivers"}
                      refresh={props.refresh}
                    />
                    <DisplayIfUserType userTypes={"superadmin"}>
                      <CreateActionDropdown
                        asset={gateway}
                        actionType={"MBUS_SEARCH"}
                        actionName={"MBus Search"}
                        refresh={props.refresh}
                      />
                    </DisplayIfUserType>
                    <DropdownItem divider />
                    <DisplayIfUserType userTypes={"superadmin"}>
                      <CreateActionDropdown
                        asset={gateway}
                        actionType={"RELEASE_SIM_CARD"}
                        actionName={"Return sim card to pool"}
                        refresh={props.refresh}
                      />
                      <CreateActionDropdown
                        asset={gateway}
                        actionType={"ROTATE_CREDENTIALS"}
                        actionName={"Rotate gateway access credentials"}
                        refresh={props.refresh}
                      />

                      <DropdownItem divider />
                    </DisplayIfUserType>
                  </>
                )}
            </DisplayIfUserType>
            {identifiedGatewayModel ? (
              <>
                <DropdownItem
                  onClick={(): void => downloadDeviceList("DEVICE_LIST")}
                >
                  Download device list
                </DropdownItem>
                <DropdownItem onClick={(): void => downloadDeviceList("SETUP")}>
                  Download setup configuration
                </DropdownItem>
              </>
            ) : (
              <>
                <DropdownItem
                  onClick={(): void =>
                    downloadDeviceList("DEVICE_LIST", "ELVACO_21XX")
                  }
                >
                  Download device list (Elvaco 21XX)
                </DropdownItem>
                <DropdownItem
                  onClick={(): void =>
                    downloadDeviceList("DEVICE_LIST", "ELVACO_31XX")
                  }
                >
                  Download device list (Elvaco 31XX)
                </DropdownItem>
                <DropdownItem
                  onClick={(): void =>
                    downloadDeviceList("SETUP", "ELVACO_21XX")
                  }
                >
                  Download setup configuration list (Elvaco 21XX)
                </DropdownItem>
                <DropdownItem
                  onClick={(): void =>
                    downloadDeviceList("SETUP", "ELVACO_31XX")
                  }
                >
                  Download setup configuration (Elvaco 31XX)
                </DropdownItem>
              </>
            )}
          </DropdownMenu>
        </Dropdown>
      )}
    </>
  );
};

export default GatewayConfigurationDropdown;
