import React from "react";
import { WirelessSurveyDataCollectorOut } from "../../openapi/model/wirelessSurveyDataCollectorOut";
import { WirelessSurveyOut } from "../../openapi/model/wirelessSurveyOut";
import DataTable, { TableColumn } from "react-data-table-component";
import { formatDate } from "../../components/utils/HighlightNullAndOldDates";
import { b64ToHex } from "../assets/gateway/data/StreamingGatewayDataTableRow";
import CopyToClipboard from "../../components/utils/CopyToClipboard";
import { WirelessSurveyDataPoint } from "../../model/assets/wirelessSurveyData";
import { Badge } from "reactstrap";
import CSVExportButton from "../../components/utils/CSVExport/CSVExportButton";

export type StreamingSurveyDataRow = [
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
];

function WirelessSurveyLiveData(props: {
  survey: WirelessSurveyOut;
  dataCollectors: WirelessSurveyDataCollectorOut[];
  data: WirelessSurveyDataPoint[];
}) {
  const dataCollectorMap = new Map(
    props.dataCollectors.map((v) => [v.dataCollectorId, v]),
  );

  const columns: TableColumn<WirelessSurveyDataPoint>[] = [
    {
      name: "Sample Point",
      grow: 2,
      cell: (row) => (
        <>
          {dataCollectorMap.get(row.dataCollectorId)?.dataCollectorName ||
            row.dataCollectorId}
        </>
      ),
    },
    {
      name: "Timestamp",
      cell: (row) => (
        <>
          <>{formatDate(row.timestamp, undefined, true)}</>
          {row.age() <= 15 && (
            <Badge color={"primary"} pill={true}>
              New
            </Badge>
          )}
        </>
      ),
      selector: (row) => row.timestamp.unix(),
      sortable: true,
    },
    {
      name: "Manufacturer",
      compact: true,
      selector: (row) => row.manufacturer,
    },
    {
      name: "Serial Number",
      compact: true,
      selector: (row) => row.serialNumber,
    },
    { name: "Medium", compact: true, selector: (row) => row.medium },
    { name: "Version", compact: true, selector: (row) => row.version },
    { name: "RSSI", compact: true, selector: (row) => row.rssi },
    {
      name: "Data",
      cell: (row) => (
        <CopyToClipboard
          value={b64ToHex(row.data)
            .toUpperCase()
            .match(/.{1,2}/g)
            ?.join(" ")}
          slice={10}
          className={"text-monospace small"}
        />
      ),
    },
  ];
  return (
    <>
      <CSVExportButton
        data={props.data.map((v) => {
          return {
            ...v,
            timestamp: v.timestamp.toISOString(true),
            data: b64ToHex(v.data)
              .toUpperCase()
              .match(/.{1,2}/g)
              ?.join(" "),
            dataCollectorName:
              dataCollectorMap.get(v.dataCollectorId)?.dataCollectorName ||
              v.dataCollectorId,
          };
        })}
        columnOrder={[
          "dataCollectorId",
          "dataCollectorName",
          "timestamp",
          "manufacturer",
          "medium",
          "version",
          "serialNumber",
          "rssi",
          "samplePointId",
          "data",
        ]}
        filename={`wireless_survey_${props.survey.surveyName}_data.csv`}
      ></CSVExportButton>
      <DataTable
        columns={columns}
        data={props.data}
        dense={true}
        pagination={true}
        paginationPerPage={100}
        paginationRowsPerPageOptions={[25, 50, 100, 250]}
        conditionalRowStyles={[
          {
            when: (row) => row.age() < 15,
            classNames: ["bg-light"],
          },
        ]}
        fixedHeader={true}
        fixedHeaderScrollHeight={"600px"}
      />
    </>
  );
}

export default WirelessSurveyLiveData;
