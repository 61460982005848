import React, { useState } from "react";
import { WirelessSurveyOut } from "../../openapi/model/wirelessSurveyOut";
import { WirelessSurveyDataCollectorOut } from "../../openapi/model/wirelessSurveyDataCollectorOut";
import {
  WirelessDataSurveyMap,
  WirelessDataSurveyMapItem,
  WirelessSurveyDataPoint,
} from "../../model/assets/wirelessSurveyData";
import DataTable, { TableColumn } from "react-data-table-component";
import { ConditionalStyles } from "react-data-table-component/dist/src/DataTable/types";
import { WirelessSurveyTarget } from "../../openapi/model/wirelessSurveyTarget";
import { Form, Formik } from "formik";
import NewRadioButtonGroup from "../../components/Forms/NewRadioButtonGroup";
import AutoSubmit from "../../components/Forms/AutoSubmit";
import { WirelessSurveySamplePoint } from "../../openapi/model/wirelessSurveySamplePoint";
import CSVExportButton from "../../components/utils/CSVExport/CSVExportButton";

interface TableData {
  serialNumber: string;
  data: WirelessDataSurveyMapItem;
  found: boolean;
  target?: WirelessSurveyTarget;
  sortOrder?: number;
}

function compareTableData(a: TableData, b: TableData): number {
  if ((a.sortOrder || 0) > (b.sortOrder || 0)) {
    return 1;
  }
  if ((a.sortOrder || 0) < (b.sortOrder || 0)) {
    return -1;
  }
  return 0;
}

function propsToTableData(
  surveyMap: WirelessDataSurveyMap,
  filter: string,
  serialNumberTargetsMap: { [serialNumber: string]: WirelessSurveyTarget },
): TableData[] {
  const surveyMapCopy = new Map([...surveyMap.entries()]);
  let maxSortOrder = 0;
  if (filter === "targets" || filter === "all") {
    /* add targets that have not been received */
    const receivedSerials: string[] = [...surveyMap.entries()].map(
      (v) => v[1].serialNumber,
    );

    Object.entries(serialNumberTargetsMap).forEach(([serial, target]) => {
      if (receivedSerials.indexOf(serial) === -1) {
        surveyMapCopy.set(serial, {
          serialNumber: serial,
          manufacturer: "",
          version: "",
          medium: "",
          dataCollectorDataMap: new Map(),
          dataCollectorSamplePointMap: new Map(),
        });
      }
      if (target.sortOrder && target.sortOrder > maxSortOrder) {
        maxSortOrder = target.sortOrder;
      }
    });
  }
  const toSortNumber = (
    serialNumber: string,
    target?: WirelessSurveyTarget,
  ): number => {
    return target?.sortOrder
      ? target.sortOrder
      : target !== undefined
        ? maxSortOrder + parseInt(serialNumber) / 100000000
        : maxSortOrder + 1 + parseInt(serialNumber) / 100000000;
  };

  return [...surveyMapCopy.entries()]
    .map((v) => {
      return {
        serialNumber: v[0],
        data: v[1],
        found: isDataReceived(v[1].dataCollectorDataMap),
        target: serialNumberTargetsMap[v[1].serialNumber],
        sortOrder: toSortNumber(
          v[1].serialNumber,
          serialNumberTargetsMap[v[1].serialNumber],
        ),
      } as TableData;
    })
    .sort(compareTableData)
    .filter((v) => {
      if (serialNumberTargetsMap === {}) {
        return true;
      }

      switch (filter) {
        case "received":
          return v.found;
        case "targets":
          return serialNumberTargetsMap[v.data.serialNumber] !== undefined;
        default:
          return true;
      }
    });
}

function isDataReceived(data: Map<string, WirelessSurveyDataPoint[]>): boolean {
  return data.size !== 0;
}
function meanRssi(data: WirelessSurveyDataPoint[]) {
  return Math.round(
    data.reduce((p, c) => p + parseInt(c.rssi), 0) / data.length,
  );
}

function samplePointRow(
  dataCollector: WirelessSurveyDataCollectorOut,
  samplePoint?: WirelessSurveySamplePoint,
) {
  return {
    name: (
      <div className={"container px-0"}>
        <div>{samplePoint?.samplePointName || "-"}</div>
        <div>
          <small>
            ({dataCollector.dataCollectorName} {dataCollector.serialNumber})
          </small>
        </div>
      </div>
    ),
    cell: (row: TableData) => {
      const data =
        row.data.dataCollectorSamplePointMap.get(
          `${dataCollector.dataCollectorId}-${samplePoint?.samplePointName || "NONE"}`,
        ) || [];
      const rssi = meanRssi(data);
      return (
        <>
          {data?.length > 0 ? (
            <>
              {data.length}&nbsp;&nbsp;<small>({rssi})</small>
            </>
          ) : (
            <>-</>
          )}
        </>
      );
    },
  };
}

function WirelessSurveySummaryTable(props: {
  survey: WirelessSurveyOut;
  dataCollectors: WirelessSurveyDataCollectorOut[];
  wirelessDataSurveyMappedData: WirelessDataSurveyMap;
  targets?: WirelessSurveyTarget[];
}) {
  const haveTargets = props.targets && props.targets.length > 0;

  const [filter, setFilter] = useState<string>("all");

  const serialNumberTargetsMap: {
    [serialNumber: string]: WirelessSurveyTarget;
  } =
    props.targets?.reduce((c, v) => {
      return { ...c, [v.serialNumber]: v };
    }, {}) || {};

  const data: TableData[] = propsToTableData(
    props.wirelessDataSurveyMappedData,
    filter,
    serialNumberTargetsMap,
  );

  const columns: TableColumn<TableData>[] = [
    {
      name: "Target ID",
      compact: true,
      selector: (row) => row.sortOrder || 0,
      cell: (row) => row.target?.sortOrder || "-",
      omit: !haveTargets,
      sortable: true,
    },
    {
      name: "Label",
      compact: true,
      selector: (row) => row.target?.label || "",
      omit: !haveTargets,
    },
    {
      name: "Serial",
      compact: true,
      selector: (row) => row.data.serialNumber,
      sortable: true,
    },
    {
      name: "Manufacturer",
      compact: true,
      selector: (row) => row.data.manufacturer,
      sortable: true,
    },
    { name: "Medium", compact: true, selector: (row) => row.data.medium },
    { name: "Version", compact: true, selector: (row) => row.data.version },
    ...(props.dataCollectors || [])
      .map((dataCollector) => {
        return [
          ...(dataCollector.samplePoints || []).map((samplePoint) =>
            samplePointRow(dataCollector, samplePoint),
          ),
          samplePointRow(dataCollector),
        ];
      })
      .flat(),
  ];
  const conditionalRowStyles: ConditionalStyles<TableData>[] = props.targets
    ? [
        {
          when: (row) =>
            serialNumberTargetsMap[row.data.serialNumber] != null && row.found,
          classNames: ["bg-success"],
        },
        {
          when: (row) => !row.found,
          classNames: ["bg-warning"],
        },
      ]
    : [];
  const dataCollectors = (props.dataCollectors || [])
    .map((dataCollector) => {
      return [
        ...(dataCollector.samplePoints || []).map(
          (samplePoint) =>
            `${dataCollector.dataCollectorId}-${samplePoint.samplePointName}`,
        ),
        `${dataCollector.dataCollectorId}-NONE`,
      ];
    })
    .flat();
  return (
    <>
      {haveTargets && (
        <Formik
          initialValues={{ targets: filter }}
          onSubmit={(values) => setFilter(values.targets)}
          enableReinitialize={true}
        >
          {() => (
            <>
              <AutoSubmit />
              <Form>
                <NewRadioButtonGroup
                  fieldName={"targets"}
                  title={"Filter"}
                  options={[
                    { value: "all", name: "Both" },
                    { value: "targets", name: "Only targets" },
                    { value: "received", name: "Only received" },
                  ]}
                  submitSpinner={false}
                  paddingX={0}
                />
              </Form>
            </>
          )}
        </Formik>
      )}
      <CSVExportButton
        download={() => {
          return Promise.resolve(
            data.map((v) => {
              return {
                targetLabel: v.target?.label,
                targetSerialNumber: v.target?.serialNumber,
                targetSortOrder: v.target?.sortOrder || 0,
                serialNumber: v.data.serialNumber,
                manufacturer: v.data.manufacturer,
                medium: v.data.medium,
                version: v.data.version,
                ...Object.fromEntries(
                  dataCollectors
                    .map((key) => {
                      return [
                        [
                          `${key}-count`,
                          v.data.dataCollectorSamplePointMap.get(key)?.length ||
                            0,
                        ],
                        [
                          `${key}-rssi`,
                          meanRssi(
                            v.data.dataCollectorSamplePointMap.get(key) || [],
                          ) || "",
                        ],
                      ];
                    })
                    .flat(),
                ),
              };
            }),
          );
        }}
        columnOrder={[
          "targetLabel",
          "targetSerialNumber",
          "targetSortOrder",
          "serialNumber",
          "manufacturer",
          "medium",
          "version",
        ]}
        filename={`wireless_survey_${props.survey.surveyName}_summary.csv`}
      />
      <DataTable
        columns={columns}
        data={data}
        dense={true}
        pagination={false}
        fixedHeader={true}
        fixedHeaderScrollHeight={"600px"}
        conditionalRowStyles={conditionalRowStyles}
      />
    </>
  );
}

export default WirelessSurveySummaryTable;
